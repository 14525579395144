
.container {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.textField {
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  border: rgb(189, 189, 189) solid 1px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0.75rem;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;

  &__invalid {
    border-color: #f93154;
  }
}

.label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.5rem;
  display: inline-block;
  line-height: 1.6;
}

.invalidLabel {
  color: #f93154;
  font-size: 0.875rem;
  padding-top: 0.25rem;
}
