
.container {

}

.button {
  color: #B1F4AB;
  background-color: rgba(0, 0, 0, 0);
  line-height: 1.6;
  font-size: 1rem;
  text-transform: uppercase;
  border: #B1F4AB solid 2px;
  border-radius: 4px;
  padding: 0.5rem 1.375rem 0.375rem;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.loadingBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: inherit;
  padding-left: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  color: inherit;
  animation: spinAround 1s infinite linear;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg),
  }

  to {
    transform: rotate(360deg),
  }
}



