@import '../../../scss/main';

.loader {
  @extend .spinnerBorder;
  @extend .spinnerBorderSm;

  margin-top: 32px;
}

.error {
  color: #f93154;
  font-size: 0.875rem;
  padding-top: 0.25rem;
}
