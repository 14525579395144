@import '../../../scss/main';

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.navbar {
  background-color: rgb(38, 38, 38);
  flex-shrink: 0;

  &__logo {
    margin-top: 1.625rem;
    margin-bottom: 1rem;
    height: 2.25rem;
  }
}

.content {
  @extend .container;
  flex-grow: 1;
}

.footer {
  background-color: rgb(38, 38, 38);
  color: #a3a3a3;
  font-size: 0.875rem;
  flex-shrink: 0;

  &__container {
    @extend .container;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__logo {
    height: 1.25rem;
  }
}
