
.container {
  padding-right: var(--#{$variable-prefix}gutter-x, #{$container-padding-x});
  padding-left: var(--#{$variable-prefix}gutter-x, #{$container-padding-x});
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }

  @media screen and (min-width: 768px) and (max-width: 992px - 1px) {
    max-width: 720px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px - 1px) {
    max-width: 960px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px - 1px) {
    max-width: 1140px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1320px;
  }
}


