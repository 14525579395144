$strength-gap: 6px;

$strength-colors: (
  #494959,
  #f93154,
  #f93154,
  #B1F4AB,
  #B1F4AB,
  #B1F4AB
);

.container {
  padding-top: 1.6rem;

  & button {
    margin-top: 10px
  }
}

.error {
  color: #f93154;
  font-size: 0.875rem;
  padding-top: 0.25rem;
}

.hintMessage {
  padding-left: 10px;
  font-weight: 400;
  font-size: 12px;
}

.strengthMeter {
  width: 50%;
  position: relative;
  height: 4px;
  background: #494959;
  margin: 7px 0;
  border-radius: 2px;
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: rgb(48, 48, 48);
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(20% + #{$strength-gap});
    z-index: 2;
  }
  &:before {
    left: calc(20% - #{($strength-gap / 2)});
  }
  &:after {
    right: calc(20% - #{($strength-gap / 2)});
  }
}

.strengthMeterFill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  transition: width 0.5s ease-in-out, background 0.25s;
  @for $i from 1 through 5 {
    &[data-strength="#{$i - 1}"] {
      width: (20% * ($i - 1));
      background: nth($strength-colors, $i);
    }
  }
}

.formField {
  padding-bottom: 20px;
}