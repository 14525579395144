body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  background-color: rgb(48, 48, 48);
  color: rgb(255, 255, 255);
}

#root {
  height: 100vh;
  width: 100vw;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

img, svg {
  vertical-align: middle;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

*:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
