$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
) !default;

$grid-gutter-width: 1.5rem !default;

$container-padding-x: $grid-gutter-width * 0.5 !default;

$variable-prefix: bs- !default;

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -0.125em !default;
$spinner-border-width: 0.25em !default;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;
